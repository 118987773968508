@import 'nullstyle';
@import './variables/colors';
@import './variables/global';

body {
    font-family: var(--font-family-main);
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
    overflow-x: hidden;
}

.app {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.app.loaded {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

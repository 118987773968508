.wrapper {
    display: flex;
    align-items: center;
}

.switcher {
    font-size: 15px;
    height: 34px;
    border-radius: 17px;
    padding: 0 24px;
    color: #000;
    background-color: transparent;
    transition: all 0.2s ease;
    cursor: pointer;

    &.active {
        background-color: #000;
        color: #fff;
    }

    &:hover {
        background-color: #000;
        color: #fff;
    }
}

@media screen and (min-width: 1024px) and (max-aspect-ratio: 8 / 5) {
    .switcher {
        font-size: 0.781vw;
        height: 1.771vw;
        border-radius: 0.8854vw;
        padding: 0 1.25vw;
    }
}

@media (max-width: 768px) {
    .switcher.active {
        display: none;
    }
}

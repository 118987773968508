.container {
    max-width: 60.938vw;
    margin: 1.903vw auto 4vw auto;
    position: relative;
    color: var(--secondary-text-color);
    flex-grow: 1;
    height: 100%;
}

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4vw;

    .logo {
        width: 8vw;
    }

    .title {
        font-size: 2.888vw;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--primary-text-color);
        font-family: var(--font-family-secondary);
        margin-left: 1.5vw;
    }
}

.textWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    .text {
        font-family: var(--font-family-secondary);
        font-weight: 500;
        font-size: 1.5vw;
        color: var(--primary-text-color);
        margin-bottom: 0.5vw;
    }

    .addressWrapper {
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    .address {
        font-family: var(--font-family-secondary);
        font-weight: 500;
        font-size: 1.5vw;
        color: var(--primary-text-color);
        margin-right: 0.4vw;
        max-width: 96vw;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.qrWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1vw;
}

.qr {
    width: 22vw;
}

.btnWrapper {
    display: flex;
    justify-content: center;
}

.dowloadLink {
    font-family: var(--font-family-secondary);
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    border: 1px solid var(--black-color);
    border-radius: 40px;
    color: var(--primary-text-color);
    padding: 0.7vw 1.7vw;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #f2f2f2;
    }
}

.btn {
    cursor: pointer;
    font-family: var(--font-family-secondary);
    font-style: normal;
    font-weight: 500;
    font-size: 1.2vw;
    border-radius: 40px;
    color: var(--secondary-text-color);
    background-color: var(--secondary-bg-color);
    padding: 0.7vw 1.7vw;
    transition: background-color 0.2s ease-in-out;
    margin-left: 1.2vw;
    &:hover {
        background-color: #505050;
    }
}

.copyButton {
    cursor: pointer;
    font-family: var(--font-family-secondary);
    font-style: normal;
    font-weight: 500;
    font-size: 0.7vw;
    border: 1px solid var(--black-color);
    border-radius: 40px;
    color: var(--primary-text-color);
    padding: 0.2vw 0.2vw;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: #f2f2f2;
    }
}

.rtlDirection {
    direction: rtl;

    .btn {
        margin-left: 0;
        margin-right: 1.2vw;
    }
}

@media (max-width: 1024px) {
    .titleWrapper {
        .logo {
            width: 134px;
            min-width: 90px;
        }
        .title {
            font-size: 30px;
        }
    }

    .textWrapper {
        .text {
            font-size: 19px;
            margin-bottom: 12px;
        }

        .address {
            font-size: 19px;
            margin-bottom: 8px;
        }
    }

    .addressWrapper {
        flex-direction: column;
    }

    .copyButton {
        font-size: 18px;
        padding: 5px;
    }

    .qr {
        width: 360px;
    }

    .dowloadLink {
        font-size: 18px;
    }

    .btn {
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .titleWrapper {
        .logo {
            width: 134px;
        }
        .title {
            font-size: 30px;
        }
    }

    .textWrapper {
        .text {
            font-size: 19px;
            margin-bottom: 12px;
        }

        .address {
            font-size: 19px;
            margin-bottom: 8px;
        }
    }

    .addressWrapper {
        flex-direction: column;
    }

    .copyButton {
        font-size: 18px;
        padding: 5px;
    }

    .qr {
        width: 360px;
    }

    .dowloadLink {
        padding: 12px;
        margin-bottom: 12px;
        text-align: center;
    }

    .btn {
        padding: 12px;
        margin: 0;
    }

    .btnWrapper {
        display: flex;
        flex-direction: column;
    }
}

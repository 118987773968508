.section {
    margin-bottom: 40px;
    padding-top: 50px;
    animation: block 5s ease-in-out;
    position: relative;
    min-height: 100vh;
}

.header {
    margin: 0 auto;
    min-width: 320px;
    max-width: 1170px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.25vw;
    z-index: 1;
    position: relative;

    @media screen and (min-width: 1024px) and (max-aspect-ratio: 8 / 5) {
        & {
            padding-left: 0.781vw;
            padding-right: 0.781vw;
            max-width: 60.938vw;
        }
    }

    @media screen and (max-width: 1023px) {
        & {
            padding-left: 45px;
            padding-right: 45px;
        }
    }

    @media (max-width: 768px) {
        & {
            padding: 0 20px;
            justify-content: space-between;
        }
    }
}

.iconWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
}

.circleIcon {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(0) scale(1);
    width: 38vw;
    transition: all 0.1s ease;
}

.circleIcon.played {
    transition: 0.5s ease-in-out;
    transform: translateY(-8.5vw) translateX(-50%) rotate(-1turn) scale(0.4);
    top: 0;
}

@keyframes circle_stroke {
    0% {
        stroke-dashoffset: 0;
        stroke-dasharray: 3401;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 6766.66259765625;
    }
}

.logoBlock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1);
    width: fit-content;
    text-align: center;
    transition: 0.5s ease-in-out;
}

.logoTitle1,
.logoTitle2 {
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Comfortaa', sans-serif;
    text-align: center;
}

.logoTitle1 {
    margin-bottom: 26px;
    font-size: 40px;
    letter-spacing: 15px;
    padding-left: 15px;
}

.logoTitle2 {
    font-size: 17px;
    letter-spacing: 7px;
    padding-left: 7px;
}

.logoTitle1,
.logoTitle2 {
    span {
        display: inline-block;
        opacity: 0;
        transition: all 0.1s ease;
    }
}

@keyframes fromTop {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fromBottom {
    0% {
        opacity: 0;
        transform: translateY(-40px);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes wave {
    0% {
        opacity: 0;
        transform: translateY(40px) translateX(-10px) rotate(30deg);
    }

    70% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
        transform: translateY(0) translateX(0) rotate(0deg);
    }
}

.section.firstRender {
    .circleIcon {
        path {
            animation: circle_stroke 2s ease forwards;
            transition: all 0.1s ease;
        }
    }

    .logoTitle2 {
        span {
            animation: wave 0.3s linear;
            animation-fill-mode: forwards;
        }

        span:nth-child(1) {
            animation-delay: 2.2s;
        }

        span:nth-child(2) {
            animation-delay: 2.3s;
        }

        span:nth-child(3) {
            animation-delay: 2.4s;
        }

        span:nth-child(4) {
            animation-delay: 2.5s;
        }

        span:nth-child(5) {
            animation-delay: 2.6s;
        }

        span:nth-child(6) {
            animation-delay: 2.7s;
        }

        span:nth-child(7) {
            animation-delay: 2.8s;
        }

        span:nth-child(8) {
            animation-delay: 2.9s;
        }

        span:nth-child(9) {
            animation-delay: 3s;
        }

        span:nth-child(10) {
            animation-delay: 3.1s;
        }

        span:nth-child(11) {
            animation-delay: 3.2s;
        }

        span:nth-child(12) {
            animation-delay: 3.3s;
        }

        span:nth-child(13) {
            animation-delay: 3.4s;
        }

        span:nth-child(14) {
            animation-delay: 3.5s;
        }

        span:nth-child(15) {
            animation-delay: 3.6s;
        }
    }

    .logoTitle1 {
        span:nth-child(odd) {
            animation: fromBottom 0.8s ease;
            animation-fill-mode: forwards;
        }

        span:nth-child(even) {
            animation: fromTop 0.8s ease;
            animation-fill-mode: forwards;
        }

        span:nth-child(1) {
            animation-delay: 2s;
        }

        span:nth-child(2) {
            animation-delay: 2.1s;
        }

        span:nth-child(3) {
            animation-delay: 2.2s;
        }

        span:nth-child(4) {
            animation-delay: 2.3s;
        }

        span:nth-child(5) {
            animation-delay: 2.4s;
        }

        span:nth-child(6) {
            animation-delay: 2.5s;
        }

        span:nth-child(7) {
            animation-delay: 2.6s;
        }
    }
}

.section.loaded {
    .logoTitle1,
    .logoTitle2 {
        span {
            display: inline-block;
            opacity: 1;
            transition: all 0.1s ease;
        }
    }
}

.formWrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.formTitle {
    font-size: 22px;
    margin-bottom: 24px;
    font-weight: 300;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0 auto;
    width: 482px;
}

.btn {
    background: url('./assets/bgButton.png') no-repeat 50%;
    background-size: contain;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    transform: scale(1);
    opacity: 1;
    transition: all 0.2s ease;
    width: 130px;
    height: 130px;
    font-size: 33px;
    padding-top: 4px;
    font-family: 'Comfortaa', sans-serif;

    &:disabled {
        cursor: default;
        opacity: 0.7;
    }
}

.header,
.formWrapper {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s linear 0.4s;
}

section.firstRender {
    .header,
    .formWrapper {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

section.loaded {
    .header,
    .formWrapper {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

.circleLoader {
    margin: 0 auto;
    width: 130px;
    height: 130px;

    path {
        animation: circle_stroke 3s ease forwards;
        transition: all 0.1s ease;
    }
}

.error {
    font-size: 1vw;
    color: #d22929;
}

.rtlDirection {
    direction: rtl;

    .logoBlock {
        direction: ltr;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    70% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    80% {
        opacity: 0.3;
        visibility: visible;
        pointer-events: none;
    }

    90% {
        opacity: 0.5;
        visibility: visible;
    }

    100% {
        opacity: 1;
        visibility: visible;
    }
}

@media (any-hover: hover) {
    .btn:hover {
        transform: scale(1.1);
        opacity: 0.8;
    }
}

@media screen and (min-width: 1024px) and (max-aspect-ratio: 8 / 5) {
    .formTitle {
        font-size: 1.146vw;
        margin-bottom: 1.25vw;
    }

    .form {
        max-width: 25.104vw;
    }

    .btn {
        font-size: 1.719vw;
        padding-top: 0.208vw;
    }

    .circleLoader {
        width: 6.771vw;
        height: 6.771vw;
    }

    .logoTitle1 {
        margin-bottom: 1.354vw;
        font-size: 2.1vw;
    }

    .logoTitle2 {
        font-size: 0.885vw;
        letter-spacing: 0.469vw;
        padding-left: 0.469vw;
    }
}

@media screen and (min-aspect-ratio: 8/5) and (min-width: 1024px) {
    .form {
        width: 44.63vh;
    }

    .formTitle {
        font-size: 2.037vh;
    }

    .logoTitle1 {
        margin-bottom: 2.407vh;
    }

    .logoTitle2 {
        font-size: 1.574vh;
        letter-spacing: 0.833vh;
        padding-left: 0.833vh;
    }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
    .form {
        position: relative;
    }

    .formTitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 1023px) {
    .logoTitle1 {
        font-size: 25px;
    }

    .logoTitle2 {
        font-size: 12px;
        letter-spacing: 6px;
        padding-left: 6px;
    }
}

@media screen and (max-width: 768px) {
    .formWrapper {
        padding: 0 40px;
    }

    .logoTitle1 {
        margin-bottom: 16px;
    }
}

@media (max-height: 779px) {
    .section.loaded,
    .iconWrapper.loaded {
        min-height: 800px;
    }
}

@media (max-width: 1350px) {
    .circleIcon.played {
        transform: translateY(0.5vw) translateX(-50%) rotate(-1turn) scale(0.4);
    }
}

@media (max-width: 1023px) {
    .circleIcon {
        width: 700px;
    }

    .circleIcon.played {
        transform: translateY(-150px) translateX(-50%) rotate(-1turn) scale(0.4);
    }

    .logoBlock {
        padding-top: 0;
    }

    .error {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .formTitle {
        font-size: 18px;
    }

    .circleIcon {
        width: 600px;
    }

    .circleIcon.played {
        transform: translateY(-90px) translateX(-50%) rotate(-1turn) scale(0.3);
    }

    .logoBlock.played {
        transform: translateX(-50%) translateY(-160%) scale(0.8);
    }

    .circleLoader {
        transform: scale(0.7);
    }

    .btn {
        transform: scale(0.7);
    }
}

@media (max-width: 600px) {
    .circleIcon {
        width: 100%;
        max-width: 600px;
        padding: 0 15px;
    }

    .circleIcon.played {
        transform: translateY(-10%) translateX(-50%) rotate(-1turn) scale(0.3);
    }

    .form {
        width: 100%;
    }
}

@media (max-width: 425px) {
    .circleIcon.played {
        transform: translateY(0) translateX(-50%) rotate(-1turn) scale(0.3);
    }

    .logoBlock.played {
        transform: translateX(-50%) translateY(-250%) scale(0.7);
    }

    .logoTitle1 {
        font-size: 21px;
    }

    .logoTitle2 {
        font-size: 11px;
    }

    .section.loaded {
        .logoTitle1 {
            font-size: 25px;
        }

        .logoTitle2 {
            font-size: 12px;
            letter-spacing: 6px;
            padding-left: 6px;
        }
    }
}

@media (max-width: 375px) {
    .formTitle {
        font-size: 16px;
    }

    .circleIcon.played {
        transform: translateY(10%) translateX(-50%) rotate(-1turn) scale(0.4);
    }
    .logoTitle1 {
        letter-spacing: 12px;
        padding-left: 12px;
    }

    .logoTitle2 {
        letter-spacing: 4px;
        padding-left: 4px;
    }

    .section.loaded {
        .logoTitle1 {
            font-size: 25px;
        }

        .logoTitle2 {
            font-size: 12px;
            letter-spacing: 6px;
            padding-left: 6px;
        }
    }
}

@media (max-width: 320px) {
    .circleIcon.played {
        transform: translateY(20%) translateX(-50%) rotate(-1turn) scale(0.4);
    }

    .logoTitle1 {
        font-size: 20px;
        letter-spacing: 9px;
        padding-left: 9px;
    }

    .logoTitle2 {
        font-size: 10px;
    }
}

.container {
    margin: 4.427vw auto 0 auto;
    color: var(--primary-text-color);
    position: relative;
}

.question {
    font-size: 1.146vw;
    margin-bottom: 0.781vw;
    font-weight: 700;
}

.answer {
    font-size: 0.99vw;
    opacity: 0.75;
    font-weight: 300;
    &:not(:last-child) {
        margin-bottom: 1.354vw;
    }
}

.images {
    position: absolute;
    width: 9.688vw;
    top: -0.781vw;
    left: -15.531vw;
}

.rtlDirection {
    direction: rtl;

    .images {
        top: -0.781vw;
        right: -15.531vw;
        left: auto;
    }
}

.image {
    height: 9.688vw;
    margin-bottom: 2.708vw;
}

@media (max-width: 1024px) {
    .container {
        margin-top: 65px;
    }

    .images {
        display: none;
    }

    .question {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
    }

    .answer {
        font-size: 19.44px;
        font-weight: 300;

        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
}

@media (max-width: 768px) {
    .container {
        margin-top: 50px;
    }

    .question {
        text-align: center;
        font-size: 18px;
        margin-bottom: 14px;
    }

    .answer {
        font-size: 17px;
        text-align: center;

        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
}

:root {
    --black-color: #000;
    --white-color: #fff;
    --gray-color: #f2f2f2;

    --primary-text-color: var(--black-color);
    --secondary-text-color: var(--white-color);

    --primary-bg-color: var(--white-color);
    --secondary-bg-color: var(--black-color);
    --third-bg-color: var(--gray-color);
}

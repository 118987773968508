.title {
    margin-left: -3.906vw;
    font-size: 2.188vw;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    font-family: var(--font-family-secondary);
    white-space: nowrap;

    &.BLACK,
    &.GRAY,
    &.WHITE {
        &:after {
            content: '';
            right: 100%;
            position: absolute;
            top: 50%;
            margin-right: 1.823vw;
            height: 1.771vw;
            margin-top: -0.99vw;
            border-radius: 0 0.885vw 0.885vw 0;
            width: 104.167vw;
        }
    }

    &.BLACK {
        color: var(--secondary-text-color);

        &:after {
            background-color: white;
        }
    }

    &.WHITE {
        color: var(--primary-text-color);

        &:after {
            background-color: black;
        }
    }

    &.GRAY {
        color: var(--primary-text-color);

        &:after {
            background-color: black;
        }
    }
}

.rtlDirection {
    margin-left: 0;
    margin-right: -3.906vw;

    &.WHITE,
    &.BLACK,
    &.GRAY {
        &:after {
            right: auto;
            left: 100%;
            margin-right: auto;
            margin-left: 1.823vw;
            border-radius: 0.885vw 0 0 0.885vw;
            width: 104.167vw;
        }
    }
}

@media (max-width: 1024px) {
    .title {
        font-size: 30px;
        margin-left: 90px;

        &.BLACK,
        &.GRAY,
        &.WHITE {
            &:after {
                margin-right: 35px;
                position: absolute;
                height: 34px;
                top: 50%;
                margin-top: -19px;
                border-radius: 0 17px 17px 0;
                width: 2000px;
            }
        }
    }

    .rtlDirection {
        margin-right: 90px;
        margin-left: 0;

        &.WHITE,
        &.BLACK,
        &.GRAY {
            &:after {
                margin-left: 35px;
                position: absolute;
                height: 34px;
                top: 50%;
                margin-top: -19px;
                border-radius: 17px 0 0 17px;
                width: 2000px;
            }
        }
    }
}

@media (max-width: 768px) {
    .title {
        text-align: center;
        margin-left: 0;
        font-size: 20px;

        &.BLACK,
        &.GRAY,
        &.WHITE {
            &:after {
                content: none;
            }
        }
    }

    .rtlDirection {
        margin-right: 0;
    }
}

@media (max-width: 425px) {
    .title {
        white-space: normal;
        line-height: 25px;
    }
}

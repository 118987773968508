.nav {
    position: relative;
}

.btn,
.closeIcon,
.burgerIcon {
    width: 29px;
    height: 16px;
}

.btn {
    background-color: transparent;
    cursor: pointer;
    position: relative;
}

.closeIcon,
.burgerIcon {
    object-fit: cover;
    position: absolute;
    right: 0;
    display: block;
    transform: scale(1) rotate(0deg);
    transition: all 0.3s ease;
}

@media screen and (min-width: 1024px) and (max-aspect-ratio: 8 / 5) {
    .btn,
    .closeIcon,
    .burgerIcon {
        width: 0.938vw;
        height: 0.938vw;
    }
}

.burgerIcon {
    top: -2px;
    opacity: 1;
    visibility: visible;
}

.closeIcon {
    opacity: 0;
    visibility: hidden;
    top: 7px;
    right: -5px;
    transform: translateY(15px) rotate(-0.3turn);
}

.navMenu {
    position: absolute;
    right: -5px;
    top: 30px;
    transform: translateY(30px);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.rtlDirection {
    .navMenu {
        right: auto;
        left: -5px;
    }

    .navItem {
        text-align: left;
    }
}

.navItem {
    height: 1.771vw;
    line-height: 1.771vw;
    white-space: nowrap;
    text-align: right;
    font-size: 17px;
}

@media screen and (min-width: 1024px) and (max-aspect-ratio: 8 / 5) {
    .navItem {
        font-size: 0.885vw;
    }
}

.navLink {
    color: #000;
    padding: 5px;
    background-color: transparent;
    transition: all 0.2s ease;
    border-radius: 0.885vw;
    line-height: 34px;
    height: 34px;

    &:hover {
        color: #fff;
        background-color: #000;
        cursor: pointer;
    }
}

@media screen and (max-width: 1024px) and (max-aspect-ratio: 8 / 5) {
    .navItem {
        height: 34px;
        line-height: 34px;
    }
}

@media (max-width: 767px) {
    .navItem {
        font-size: 15px;
    }
}

.nav.open {
    .burgerIcon {
        opacity: 0;
        visibility: hidden;
    }

    .closeIcon {
        opacity: 1;
        visibility: visible;
    }

    .navMenu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

.closeIcon.opened {
    animation: opened 0.7s ease;
    top: -1px;
    right: 0;
    transform: translateY(0) rotate(0) scale(1);
}

.burgerIcon.closed {
    animation: closed 0.7s ease;
}

@keyframes opened {
    0% {
        transform: rotate(0deg) scale(0);
        visibility: hidden;
    }

    100% {
        transform: rotate(1turn) scale(1) translateX(0) translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes closed {
    0% {
        transform: rotate(0deg) scale(0);
        visibility: hidden;
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        transform: rotate(-1turn) scale(1);
        opacity: 1;
        visibility: visible;
    }
}

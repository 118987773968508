.container {
    margin: 0 auto;
    color: var(--primary-text-color);
}

.portrait {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.385vw;
}

.mainImage {
    width: 100%;
}

.mainImageWrapper {
    position: relative;
    width: 24.792vw;
    min-width: 24.792vw;
}

.title {
    position: absolute;
    font-size: 1.458vw;
    font-weight: 700;
    color: var(--secondary-text-color);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.bitcoinImage {
    width: 6.875vw;
    min-width: 6.875vw;
    margin-left: 0.781vw;
    height: 3.802vw;
}

.smileImage {
    width: 7.344vw;
    min-width: 7.344vw;
    height: 7.031vw;
    margin-left: 1.042vw;
    margin-right: 5.938vw;
}

.countryImage {
    width: 7.292vw;
    min-width: 7.292vw;
    margin-right: 0.521vw;
    margin-bottom: 0.521vw;
}

.countryWrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.countryLabel {
    font-size: 1.146vw;
    font-weight: 700;
    max-width: 100px;
}

.text {
    font-size: 0.99vw;
    font-weight: 300;
}

.line:not(:last-child) {
    margin: 0 0 1.042vw;
}

.arrow {
    position: absolute;
    left: -4.5vw;
    width: 4.093vw;
}

.arrow1 {
    top: 55%;
}

.arrow3 {
    bottom: 55%;
}

.rtlDirection {
    .countryImage {
        margin-left: 0.521vw;
        margin-right: 0;
    }

    .bitcoinImage {
        margin-right: 0.781vw;
        margin-left: 0;
    }

    .smileImage {
        margin-right: 1.042vw;
        margin-left: 5.938vw;
    }

    .arrow {
        left: 14vw;
    }

    .arrow1 {
        transform: rotate(240deg);
    }

    .arrow2 {
        transform: rotate(180deg);
    }

    .arrow3 {
        transform: rotate(120deg);
    }

    .bitcoinImage {
        display: none;
    }

    .countryLabel {
        max-width: 54px;
    }
}

@media (max-width: 1024px) {
    .text {
        font-size: 19.44px;
        font-weight: 300;
    }

    .line {
        margin: 0 0 20px !important;
    }

    .portrait {
        justify-content: center;
        flex-wrap: wrap;
    }

    .mainImageWrapper {
        width: 476px;
        min-width: 476px;
        margin-right: 100px;
        margin-left: 100px;
        margin-bottom: 40px;
    }

    .title {
        font-size: 28px;
    }

    .countryWrapper,
    .bitcoinImage,
    .smileImage {
        display: none;
    }
}

@media (max-width: 768px) {
    .text {
        font-size: 17px;
        font-weight: 300;
        opacity: 0.75;
    }
    .mainImage,
    .mainImageWrapper {
        width: 275px;
        height: 275px;
    }

    .mainImage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .title {
        font-size: 17px;
    }
}
